import { useMutation, useQuery } from '@apollo/react-hooks';
import { Spinner, Error } from 'lib';
import React, { useMemo } from 'react';
import {
  allCollections,
  AllCollectionType,
  saveCollectionData,
  SaveCollectionDataVariables
} from './queries';
import { CollectionData, CollectionDataTableProps } from './types';

export const CollectionDataFetcher: React.FC<{
  component: React.ComponentType<CollectionDataTableProps>;
}> = ({ component: Component }) => {
  const { loading, error, data, refetch } = useQuery<AllCollectionType>(
    allCollections
  );
  const [saveInternal, { loading: saving, error: mutationError }] = useMutation<
    number,
    SaveCollectionDataVariables
  >(saveCollectionData);

  const save = async (data: CollectionData): Promise<void> => {
    await saveInternal({
      variables: {
        toSave: [{ id: data.id, priority: data.priority }]
      }
    });
    await refetch();
  };
  const sortedData: CollectionData[] = useMemo(() => {
    if (!data) return [];
    return data.metaEntries
      .sort((a, b) => {
        if (b.value.priority - a.value.priority !== 0) {
          return b.value.priority - a.value.priority;
        }
        return b.value.collectionActivateTime - a.value.collectionActivateTime;
      })
      .map((e) => ({
        id: e.internalId,
        imgUri: e.value.imgUri,
        name: e.value.name,
        priority: e.value.priority
      }));
  }, [data]);
  if (loading && !data) {
    return <Spinner />;
  }
  if (error) {
    console.error(error);
    return <Error data={error.message} />;
  }
  if (mutationError) {
    console.error(mutationError);
    return <Error data={mutationError.message} />;
  }
  return <Component data={sortedData} save={save} saving={saving || loading} />;
};
