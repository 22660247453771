import gql from 'graphql-tag';
import { CollectionDataInput } from './types';

export interface AllCollectionType {
  metaEntries: {
    id: string;
    internalId: string;
    value: {
      name: string;
      imgUri: string;
      priority: number;
      collectionActivateTime: number;
    };
  }[];
}
export const allCollections = gql`
  query collections {
    metaEntries(type: "COLLECTION") {
      id
      internalId
      value {
        ... on CollectionMetaEntryValue {
          name
          imgUri
          priority
          collectionActivateTime
        }
      }
    }
  }
`;

export interface SaveCollectionDataVariables {
  toSave: CollectionDataInput[];
}

export const saveCollectionData = gql`
  mutation savePriority($toSave: [CollectionDataInput!]!) {
    saveCollectionData(toSave: $toSave)
  }
`;
