import { EditableTable, EditableTableColumn, ImageRendererCreator } from 'lib';
import React from 'react';
import { CollectionData, CollectionDataTableProps } from './types';

declare type CollectionDataType = CollectionData;
export const CollectionsTable: React.FC<CollectionDataTableProps> = ({
  data,
  save,
  saving
}) => {
  const saveData = (id: string, data: CollectionData) => save({ ...data, id });
  const idDataIndex = 'id';
  const columns: EditableTableColumn<CollectionDataType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      editable: false,
      width: '10%',
      searchable: true
    },
    {
      title: 'Image',
      dataIndex: 'imgUri',
      width: '15%',
      render: ImageRendererCreator(50),
      editable: false,
      searchable: false
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: false,
      width: '50%',
      searchable: true
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      editable: true,
      width: '15%',
      searchable: false
    }
  ];
  const actionsColumn = {
    title: 'Actions',
    dataIndex: 'actions',
    width: '10%'
  };
  return (
    <EditableTable
      actionsColumn={actionsColumn}
      columns={columns}
      idDataIndex={idDataIndex}
      data={data}
      saveData={saveData}
      saving={saving}
    />
  );
};
