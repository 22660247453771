import React from 'react';
import './style/main.less';
import { ProductsPage } from './pages/Products';
import logo from './images/logo.svg';
import {
  MainLayout,
  Page,
  AuthenticatedRoute,
  TranslationsPage,
  JobsPage,
  GraphQLPage,
  MaterialsPage
} from 'lib';
import { CollectionsPage } from 'pages/Collections';
const Logo: React.FC = () => (
  <div className="logo">
    <img src={logo} alt="logo" />
  </div>
);

const pages: Page[] = [
  {
    label: 'Products',
    key: 'products',
    routeComponent: AuthenticatedRoute,
    render: () => <ProductsPage />
  },
  {
    label: 'Translations',
    key: 'translations',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <TranslationsPage
        langs={{
          sv: 'Swedish',
          en: 'English',
          ru: 'russian'
        }}
      />
    )
  },
  {
    label: 'Collections',
    key: 'collections',
    routeComponent: AuthenticatedRoute,
    render: () => <CollectionsPage />
  },
  {
    label: 'Jobs',
    key: 'jobs',
    routeComponent: AuthenticatedRoute,
    render: () => <JobsPage />
  },
  {
    label: 'Materials',
    key: 'materials',
    routeComponent: AuthenticatedRoute,
    render: () => <MaterialsPage />
  },
  {
    label: 'Console',
    key: 'graphql-console',
    routeComponent: AuthenticatedRoute,
    render: () => <GraphQLPage />
  }
];

export const App: React.FunctionComponent = () => (
  <MainLayout pages={pages} logo={Logo} />
);
